* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.nav-link, .nav-dropdown, .nav-dropdown-item {
  border-radius: 1rem;
}

.nav-link:hover, .nav-dropdown:hover .dropdown-toggle, .nav-dropdown-item:hover {
  color: white;
  background-color: #0d6efd;
}

.nav-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-icon:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}